const GLOBAL_FOOTER = {
    HOME: 'https://www.telstra.com.au/',
    CONSUMER_ADVICE: 'https://www.telstra.com.au/consumer-advice',
    HELP_AND_SUPPORT: 'https://www.telstra.com.au/support/mobiles-devices/activate-sim-pre-paid',
    CRITICAL_INFORMATION_SUMMARIES: 'https://www.telstra.com.au/help/critical-information-summaries',
    TERMS_OF_USE: 'https://www.telstra.com.au/terms-of-use',
    PRIVACY: 'https://www.telstra.com.au/privacy'
};

export default GLOBAL_FOOTER;
