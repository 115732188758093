import reactDom from 'react-dom';
import { MessageGlobal } from '@able/react';
import React from 'react';
import { getAbleSpriteSheet, isOutageWindow } from '../../../adapters/utils';

const ableSpriteSheet = getAbleSpriteSheet();

export function GlobalMessage() {

    const { PPA_GLOBAL_BANNER_TEXT, PPA_GLOBAL_BANNER_START_TIME, PPA_GLOBAL_BANNER_END_TIME } = window.appConfig;
    const show = isOutageWindow(PPA_GLOBAL_BANNER_START_TIME, PPA_GLOBAL_BANNER_END_TIME);

    const globalMessages = [];

    // Global banner message
    if (show) {
        globalMessages.push(
            <MessageGlobal
                key="global-banner"
                developmentUrl={ableSpriteSheet}
                variant="Attention"
                description={PPA_GLOBAL_BANNER_TEXT}
            />
        );
    }

    // 3G exit global banner
    globalMessages.push(
        <MessageGlobal
            developmentUrl={ableSpriteSheet}
            variant="Attention"
            description={
                <>
                    Before you activate, check that your device will work when our 3G network closes from 28 October 2024.
                    3G devices and some older 4G devices won’t work post the closure.{' '}
                    <a href="https://www.telstra.com.au/support/mobiles-devices/3g-closure" target="_blank">More about the 3G closure.</a>
                </>
            }
        />
    );

    return reactDom.createPortal(
        <div>
            {globalMessages}
        </div>,
        document.getElementById('global-message')
    );
}
