const PAYMENT_PAGE_CONSTANTS = {

    priceComparisonTableConfig: {
        title: 'Our recharge options are changing',
        tableData: {
            TPPCP: {
                label: 'Telstra Prepaid Mobile Plan',
                smallTitle: 'From 22 October 2024, our recharge prices are increasing.',
                columns: [
                    {
                        label: 'Current price',
                        dataKey: 'currentPlan'
                    },
                    {
                        label: 'New price from 22 October 2024',
                        dataKey: 'newPlan'
                    }
                ],
                data: [
                    {
                        currentPlan: '$12',
                        newPlan: '$13'
                    },
                    {
                        currentPlan: '$35',
                        newPlan: '$39'
                    },
                    {
                        currentPlan: '$45',
                        newPlan: '$49'
                    },
                    {
                        currentPlan: '$55',
                        newPlan: '$59'
                    },
                    {
                        currentPlan: '$65',
                        newPlan: '$69'
                    },
                    {
                        currentPlan: '$160',
                        newPlan: '$180'
                    },
                    {
                        currentPlan: '$320',
                        newPlan: '$350'
                    }
                ]
            },
            TPPGB: {
                label: 'Telstra Prepaid Casual Plan',
                smallTitle: 'From 22 October 2024, our recharge prices are increasing, and some plan inclusions are changing.',
                columns: [
                    {
                        label: 'Current plan',
                        dataKey: 'currentPlan'
                    },
                    {
                        label: 'New plan from 22 October 2024',
                        dataKey: 'newPlan'
                    }
                ],
                data: [
                    {
                        currentPlan: '$23 for 200MB data, 50 mins, 65 SMS/MMS',
                        newPlan: '$25 for 220MB data, 55 mins, 70 SMS/MMS'
                    },
                    {
                        currentPlan: '$35 for 350MB data, 95 mins, 120 SMS/MMS',
                        newPlan: '$39 for 390MB data, 110 mins, 140 SMS/MMS'
                    },
                    {
                        currentPlan: '$55 for 550MB data, 160 mins, 185 SMS/MMS',
                        newPlan: '$59 for 590MB data, 180 mins, 200 SMS/MMS'
                    },
                    {
                        currentPlan: '$70 for 700MB data, 200 mins, 200 SMS/MMS',
                        newPlan: '$74 for 740MB data, 220 mins, 220 SMS/MMS'
                    },
                    {
                        currentPlan: '$100 for 1GB data, 300 mins, 300 SMS/MMS',
                        newPlan: '$120 for 1.2GB data, 360 mins, 360 SMS/MMS'
                    }
                ]
            },
            TPPMB: {
                label: 'Telstra Prepaid Mobile Broadband Plan',
                smallTitle: 'From 22 October 2024, our recharge prices are increasing.',
                columns: [
                    {
                        label: 'Current price',
                        dataKey: 'currentPlan'
                    },
                    {
                        label: 'New price from 22 October 2024',
                        dataKey: 'newPlan'
                    }
                ],
                data: [
                    {
                        currentPlan: '$23',
                        newPlan: '$25'
                    },
                    {
                        currentPlan: '$35',
                        newPlan: '$39'
                    },
                    {
                        currentPlan: '$55',
                        newPlan: '$59'
                    },
                    {
                        currentPlan: '$70',
                        newPlan: '$74'
                    },
                    {
                        currentPlan: '$160',
                        newPlan: '$180'
                    },
                    {
                        currentPlan: '$320',
                        newPlan: '$350'
                    }
                ]
            }
        }
    }
};

export default PAYMENT_PAGE_CONSTANTS;
