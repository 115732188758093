import React from 'react';
import { showWebMessenger } from '../../adapters/utils';

const MessageUs = (props) => {
    const openChat = () => {
        const { message, errorType } = props;
        showWebMessenger(message, errorType);
    };

    return (
        <span className="button-as-link" role="button" tabIndex="0" onClick={() => openChat()}>
            {props.text || 'Message us'}
        </span>
    );
};

export default MessageUs;
