import { Error, Loader, AutoComplete, Select } from '../../components';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import React, { Component } from 'react';
import { getCustomerState, nextPage, getCurrentRouteName, submitFailure, getFormValues, scrollToBody, getAbleSpriteSheet, focusOnElement, isSelectedDeviceMBB } from '../../adapters/utils';
import { connect } from 'react-redux';
import { SERVER_ERROR, BAD_REQUEST, SHOPPING_CART_CREATION_ERROR, NETWORK_FAILURE, NONPHYSICAL_ADDRESS_ERROR } from '../../adapters/errorCode';
import { getUiState } from '../../adapters/store-utils';
import './your-contact-details.css';
import CONTACT_PAGE_CONSTANTS from './constants';
import { validateBizName, validateAbnNumber, email as validateEmail, isEmpty } from '../../adapters/validations';
import FullPageError from '../../components/FullPageError/FullPageError';
import SpinningButton from '../../components/SpinningButton/SpinningButton';
import { dataLayerEventActions } from '../../adapters/constants';
import { addDataLayerEventInfo } from '../../adapters/analytics-utils';
import { redirectToMyTelstra } from '../../adapters/esim-utils';
import EditContactDetails from './components/editContactDetails/editContactDetails';
import UpdateContact from './updatecontact';
import { BusinessRegistration } from './components/businessRegistration/businessRegistration';
import { AbleCheckBox } from '../../components/form/AbleCheckBox';
import GlobalBanner from '../../common/components/globalBanner/globalBanner';
import ReadOnlyField from './components/readonlyField/readOnlyField';
import { MessageInline, TextStyle, Spacing, MessageSection } from '@able/react';
import PersonalEmailVerification from './components/PersonalEmailVerification/personalEmailVerification';
import bannerImage from '../../assets/img/svg/location-pin.svg';

const ableSpriteSheet = getAbleSpriteSheet();

export class YourContactDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSoleTrader: !!getFormValues().abn,
            showIdVerifiedMessage: false
        };
        this.verifyAddress = this.verifyAddress.bind(this);
        this.submitContactForm = this.submitContactForm.bind(this);
        this.checkExistingCustomerStatus = this.checkExistingCustomerStatus.bind(this);
        this.toggleSoleTraderForm = this.toggleSoleTraderForm.bind(this);
    }
    /**
     * Fetch AEM Data
     * @param {any} props
     * @memberof ContactDetails
     */
    componentWillMount() {
        const {
            actions: { moduleInit },
            loading: { isModuleLoaded }
        } = this.props;
        if (!isModuleLoaded) {
            moduleInit();
        }
    }

    componentDidMount() {
        const pageNameArr = getCurrentRouteName();
        document.title = pageNameArr.pageName;
        scrollToBody();
        setTimeout(() => {
            if (document.getElementById('contact-details-toast-1')) {
                this.setState({ showIdVerifiedMessage: true });
            }
            const h1 = document.querySelector('h1');
            if (h1) h1.focus();
        }, 500);

        const stepperData = {
            totalSteps: this.props.appData.uiState.isEsimActivation ? 7 : 6,
            currentStep: 4,
            isLastStepCompleted: false
        };
        this.props.actions.updateStepperCustomData(stepperData);
    }

    render() {
        const {
            app: { customerDataFetchSuccess },
            appData: {
                customer: {
                    found: customerFound,
                    data: { matchedContact, phoneNumber }
                },
                uiState: { isMfafromEdit, editingField, userSelectedDevice },
                levelOfAuthentication
            },
            updateMobileStatus: { didUserUpdateMobile, unAuthUpdateAddNumberSkip }
        } = this.props;
        const {
            loading: { appLoading, customerCheckInProgress, verificationInProgress, cartCreated },
            handleSubmit,
            formData = {},
            verifyingBusinessAddress
        } = this.props;
        const formSubmitted = cartCreated;
        if (appLoading) return <Loader />;

        const {
            uiState: { appLock, loggedIn, requestPath }
        } = this.props.appData;

        const disableFields = formSubmitted || customerCheckInProgress || verificationInProgress || verifyingBusinessAddress;
        const {
            verifyAddressId,
            address: { isVerificationRequired = false },
            addressValue
        } = this.props;
        const {
            address: { verified: addressVerified, verificationOptions }
        } = this.props.appData;
        const { loggedInCustomerData: { anonymous, customerAccountUUID = null, adborAddressId = '' } = {} } = this.props.app;

        const { isEsimActivation = false, applock } = getUiState() || {};

        const verifyAddressValue = verifyAddressId !== 'addressNotFound' && isVerificationRequired ? verificationOptions.filter((verifiedAddress) => verifiedAddress.value === verifyAddressId)[0] : addressValue;
        // conditional rendering variables
        const showConfirmButton = !addressVerified && !appLock && !customerFound && !adborAddressId;
        const showLiveChatButton = (verifyAddressId === 'addressNotFound' && isVerificationRequired) || appLock;
        const showNextButton = (customerFound || addressVerified) && !showLiveChatButton && !appLock;
        // show cog only on verify address
        const showConfirmAddressProgressCog = requestPath && requestPath.indexOf('verifyaddress') !== -1;
        const verifyChangedAddress = () => {
            const {
                actions: { addressVerifiedStatus, resetErrors }
            } = this.props;
            resetErrors();
            addressVerifiedStatus(false);
        };
        const { hasErrorOccurred, hasServiceError } = getCustomerState();
        const {
            errors = {},
            errors: {
                hasErrorOccurred: ycdHasErrorOccurred,
                errorText,
                errorMessage
            } = {},
            appData: {
                address: { addressData = {} },
                uiState: { selectedIdentityType, selectedDevice }
            }
        } = this.props;
        const IdentityTypeVisa = selectedIdentityType === 'AUS_VISA';
        const addressVal = addressData.address;
        const { pageTitle, EmailAddress, Address, SoleTraderCheck } = CONTACT_PAGE_CONSTANTS;
        let errorProps = {};
        if (hasServiceError) {
            errorProps = {
                errorMessage: 'You’ve reached the maximum number of Pre-Paid services on your account.',
                errorMessage1: 'Currently we can only support the activation of 35 services on a single account.',
                errorText: 'Unable to process request',
                isEsimActivation
            };
        } else {
            errorProps = {
                errorMessage: 'We’re unable to process the request at this time. Please try again later.',
                errorText: 'Something went wrong'
            };
        }
        if (ycdHasErrorOccurred && errorText && errorMessage) {
            errorProps = {
                errorMessage,
                errorText
            };
        }

        const isAnonymousCustomer = loggedIn && anonymous;

        let nonPhysicalAddressError = '';
        if (errors.errorCode === NONPHYSICAL_ADDRESS_ERROR) {
            nonPhysicalAddressError = CONTACT_PAGE_CONSTANTS.nonPhysicalAddressErrorMeta.error;
        }

        const isMBBActivation = isSelectedDeviceMBB(selectedDevice);
        const phoneNumberUpdationSkipped = isMBBActivation ? unAuthUpdateAddNumberSkip : phoneNumber && unAuthUpdateAddNumberSkip;
        return (
            <div className="container ppa-block id-page contact-details-page">
                {!hasErrorOccurred && !ycdHasErrorOccurred && <GlobalBanner onPage="contactdetails" className="infobanner-contactdetails" />}
                {(!loggedIn || isAnonymousCustomer || !customerDataFetchSuccess) &&
                    !hasErrorOccurred &&
                    (!matchedContact || didUserUpdateMobile || phoneNumberUpdationSkipped) &&
                    !hasServiceError &&
                    !ycdHasErrorOccurred && (
                        <div className="row">
                            <div className="col-lg-5 contact-details-form">
                                <div id="contact-details-toast-1" role="status" aria-atomic="false">
                                    {this.state.showIdVerifiedMessage && !cartCreated && (
                                        <Spacing bottom="spacing3x" top="spacing2x">
                                            <MessageInline developmentUrl={ableSpriteSheet} variant="Success" description="Your ID has been verified." />
                                        </Spacing>
                                    )}
                                </div>
                                <TextStyle element="h1" alias="HeadingA" tabIndex="-1">
                                    {pageTitle}
                                </TextStyle>
                                {cartCreated ? (
                                    <MessageInline developmentUrl={ableSpriteSheet} className="mt-5 mb-2" variant="Information" description="Please note you can't change contact details." />
                                ) : (
                                    <TextStyle alias="TextBodyLong" className="mt-5 mb-2">
                                        We'll add your contact details to our records.
                                    </TextStyle>
                                )}
                                <div>
                                    <form>
                                        <div className={`select-identity mt-3 ${cartCreated ? 'customerCheckDone' : ''}`}>
                                            {formSubmitted ? (
                                                <ReadOnlyField label={EmailAddress.label} value={formData.contactEmail} />
                                            ) : (
                                                <PersonalEmailVerification isPersonalEmailVerified={this.isPersonalEmailVerified} disabled={disableFields} />
                                            )}
                                            {(!adborAddressId || isAnonymousCustomer) && (
                                                <fragment>
                                                    {IdentityTypeVisa && !formSubmitted &&
                                                        <MessageSection
                                                            className="mbxl"
                                                            variant="Information"
                                                            developmentUrl={ableSpriteSheet}
                                                            description={Address.tipMessage} />}
                                                    {formSubmitted ? (
                                                        <ReadOnlyField label={Address.label} value={addressVal} />
                                                    ) : (
                                                        <Field
                                                            name="address"
                                                            id="addressId"
                                                            className="mt-3"
                                                            component={AutoComplete}
                                                            able
                                                            customErrorMessage={nonPhysicalAddressError}
                                                            formSubmitError={errors.errorCode === NONPHYSICAL_ADDRESS_ERROR}
                                                            type="text"
                                                            label={Address.label}
                                                            aria-label={Address.tipMessage}
                                                            placeholder={Address.placeholder}
                                                            loadOptions={(props) => this.getAddressList(props)}
                                                            selectedValue={verifyAddressValue}
                                                            showTick={addressVerified}
                                                            aria-required="true"
                                                            onChange={() => verifyChangedAddress()}
                                                            assistiveText={Address.assistiveText}
                                                            disabled={disableFields}
                                                        />
                                                    )}
                                                </fragment>
                                            )}
                                            <fieldset disabled={disableFields}>
                                                {(!loggedIn || isAnonymousCustomer || !customerDataFetchSuccess) && userSelectedDevice !== 'Watch' && !cartCreated && (
                                                    <Field
                                                        component={AbleCheckBox}
                                                        name="soleTraderCheck"
                                                        checked={this.state.showSoleTrader}
                                                        id="soleCheckId"
                                                        className="check-sole-trader mt-3 mb-2"
                                                        label={SoleTraderCheck.label}
                                                        onChange={this.toggleSoleTraderForm}
                                                    />
                                                )}
                                                <div className="sr-only" aria-atomic="true" aria-live="assertive" id="ycd-ariaLive-slot2" />
                                                {this.state.showSoleTrader && <BusinessRegistration isReadOnlyForm={formSubmitted} disabled={disableFields} />}
                                            </fieldset>
                                            {applock && this.renderErrors()}
                                        </div>
                                    </form>
                                </div>
                                <SpinningButton
                                    stylingClass="mob-button my-4"
                                    onSubmit={handleSubmit(this.submitContactForm)}
                                    isDisabled={customerCheckInProgress || verificationInProgress || verifyingBusinessAddress}
                                    isLoading={customerCheckInProgress || verificationInProgress || verifyingBusinessAddress}
                                    buttonText="Continue"
                                />
                            </div>
                            <div className="col-lg-5">{bannerImage && <img className="contact-details-image hide-in-mobile" alt="" src={bannerImage} />}</div>
                        </div>
                    )}
                {!loggedIn && matchedContact && !didUserUpdateMobile && !phoneNumberUpdationSkipped && !hasErrorOccurred && !ycdHasErrorOccurred && !hasServiceError && <UpdateContact {...this.props} />}
                {loggedIn && !isAnonymousCustomer && customerDataFetchSuccess && !hasErrorOccurred && !hasServiceError && !ycdHasErrorOccurred && (
                    <EditContactDetails
                        showIdVerifiedMessage={this.state.showIdVerifiedMessage}
                        validateBusinessRegistrationForm={this.validateBusinessRegistrationForm}
                        validateBusinessContactsForm={this.validateBusinessContactsForm}
                        bannerImage={bannerImage}
                        isMfafromEdit={isMfafromEdit}
                        levelOfAuthentication={levelOfAuthentication}
                        editingField={editingField}
                        updatePhoneProps={{ ...this.props }}
                        updateMobileStatus={this.props.updateMobileStatus}
                    />
                )}
                {hasServiceError && <FullPageError {...errorProps} />}
                {(hasErrorOccurred || (this.props.errors.errorCode && this.props.errors.errorCode === NETWORK_FAILURE)) && (isEsimActivation ? redirectToMyTelstra() : <FullPageError {...errorProps} />)}
            </div>
        );
    }
    toggleSoleTraderForm() {
        const { showSoleTrader } = this.state;
        if (showSoleTrader) {
            this.setState({ showSoleTrader: false });
        } else {
            this.props.handleSubmit(() => {
                if (!this.isPersonalEmailVerified()) {
                    return focusOnElement('#ACMA-error', 0);
                }
                this.setState({ showSoleTrader: true });
            })();
        }
    }

    checkExistingCustomerStatus() {
        const { invalid } = this.props;
        const { contactEmail, msisdn, customerAccountUUID } = getCustomerState();
        const {
            actions: { checkExistingCustomer, createShoppingCart, updateStepperCustomData }
        } = this.props;
        const {
            appData: {
                customer: { found: customerFound },
                uiState: { loggedIn = false }
            }
        } = this.props;
        if (!invalid) {
            addDataLayerEventInfo(dataLayerEventActions.EMAIL_ENTERED);
            if (!loggedIn) {
                checkExistingCustomer(contactEmail, msisdn);
            } else if (!customerFound) {
                this.submitContactForm();
            } else if (loggedIn && customerFound) {
                createShoppingCart(customerAccountUUID);
                if (this.props.loading.cartCreated) {
                    nextPage(updateStepperCustomData);
                }
            } else if (customerFound) {
                nextPage(updateStepperCustomData);
            }
        }
    }
    /**
     * Service call to get the values to match the address autocomplete.
     * @param {any} input --> address input value
     * @returns array of matches
     * @memberof AddressDetails
     */
    getAddressList(input) {
        if (!input) {
            return Promise.resolve({ options: [] });
        }
        const { actions: { getAddress } = {} } = this.props;
        return getAddress(input).then((response) => {
            const address = [];
            if (!response || !response.data) return address;
            response.data.results.forEach((value) => {
                address.push({
                    label: value.name,
                    value: value.name
                });
            });
            return Promise.resolve(address);
        });
    }

    /**
     * Verifies the address based on the address id which is
     * received from the global state. This global state is changed based on
     * the option selected in the address box.
     *
     * @param {any} values -> form values
     * @param {any} dispatch -> redux dispatch
     * @param {any} props -> component props
     * @memberof AddressDetails
     */
    async verifyAddress(addressParams) {
        const { verifyAddress } = this.props.actions;
        await verifyAddress(addressParams);
    }

    /**
     * renders address verification option received from service call.
     * Also add's a implicit label for address not found.
     * this render is conditionally called based on address verification state
     * @memberof AddressDetails
     */
    renderVerifyAddress() {
        const addressList = {
            label: 'Please confirm address',
            placeholder: 'Please select an address',
            notListedOption: 'My address is not listed above'
        };
        // verification options
        const {
            address: { verificationOptions = [] }
        } = this.props.appData;

        const isAddressNotFoundOptionAvailable = verificationOptions.filter((address) => address.value === 'addressNotFound').length > 0;
        if (!isAddressNotFoundOptionAvailable && verificationOptions.length !== 0) {
            verificationOptions.push({ label: addressList.notListedOption, value: 'addressNotFound' });
        }

        const matchAddresses = (event) => {
            const { value: addressId = '' } = event.target || {};
            const verifiedAddress = verificationOptions.find((address) => address.value === addressId);
            if (!isEmpty(verifiedAddress) && verifiedAddress.value !== 'addressNotFound') {
                this.props.change('address', verifiedAddress);
            }
        };

        return (
            <Field
                name="verifyAddress"
                component={Select}
                label={addressList.label}
                placeholder={'Start typing'}
                aria-label={addressList.label}
                prompt={addressList.placeholder}
                options={verificationOptions}
                aria-required="true"
                onChange={(event) => matchAddresses(event)}
            />
        );
    }

    /**
     * Personal form submit action
     *
     * @param {any} values
     * @memberof YourDetails
     */
    async submitContactForm() {
        // const {
        //     loading: { customerCheckDone = false }
        // } = this.props;
        // if (customerCheckDone) {
        //     return nextPage(updateStepperCustomData);
        // }
        addDataLayerEventInfo('click', 'clickTrack', 'buttonClick', 'Continue');
        const {
            actions: { validateCustomerDetails, updateStepperCustomData, verifyBusinessAddress },
            app: { loggedInCustomerData: { anonymous = true, customerAccountUUID = null, adborAddressId = null } = {}, customerDataFetchSuccess } = {},
            appData: {
                uiState: { loggedIn = false },
                address: {
                    addressData: { addressId = '' },
                    verificationData = {}
                }
            },
            updateMobileStatus: {
                didUserUpdateMobile,
                unAuthUpdateAddNumberSkip
            },
            loading: { cartCreated }
        } = this.props;
        if (!this.isPersonalEmailVerified()) {
            return focusOnElement('#ACMA-error', 0);
        }
        if (this.state.showSoleTrader) {
            if (!this.validateBusinessRegistrationForm().valid) return;
            else if (!(await this.validateBusinessContactsForm()).valid) return;
        } else {
            this.props.change('soleTraderCheck', false);
        }

        if ((!loggedIn || !customerDataFetchSuccess) && (didUserUpdateMobile || unAuthUpdateAddNumberSkip || cartCreated)) {
            this.props.change('soleTraderCheck', this.state.showSoleTrader);
            nextPage(updateStepperCustomData);
            return;
        }

        const addressLine1 = addressId;
        if (!isEmpty(addressLine1) || (loggedIn && isEmpty(adborAddressId))) {
            await this.verifyAddress({ addressLine1 });
        }
        // const navigate = ((loggedIn && !anonymous) || customerAccountUUID) && !this.props.errors.hasError;
        // if (navigate) {
        //     nextPage(updateStepperCustomData);
        // } else {
        const {
            abn = null,
            acn = null,
            businessType = null,
            companyName = null,
            contactEmail = null,
            customerType = null,
            dobDay = null,
            dobMonth = null,
            dobYear = null,
            firstName = null,
            fullAddressDetails = null,
            lastName = null,
            middleName = null,
            licenseNumber = null,
            postCode = null,
            title = null,
            stateOfIssue = null,
            passportNumber = null,
            visaNumber = null,
            countryOfIssue = null,
            cardColour = null,
            cardNumber = null,
            expiry = null,
            individualReferenceNumber = null,
            noMiddleName = null,
            businessPhone = null,
            businessAddress = null,
            businessEmail = null
        } = getFormValues();
        const payload = {
            abn,
            acn,
            businessType,
            companyName,
            contactEmail,
            customerType,
            dobDay,
            dobMonth,
            dobYear,
            firstName,
            middleName: noMiddleName ? '' : middleName,
            fullAddressDetails,
            lastName,
            licenseNumber,
            postCode,
            title,
            stateOfIssue,
            passportNumber,
            visaNumber,
            countryOfIssue,
            cardColour,
            cardNumber,
            expiry,
            individualReferenceNumber,
            businessPhone,
            businessEmail,
            businessAddress
        };
        payload.customerStatus = 'NEW';
        this.state.showSoleTrader && addDataLayerEventInfo('click', 'clickTrack', 'optInCheckbox', 'This service will be for business use');
        if (!this.props.errors.hasError && (!isEmpty(verificationData) || !cartCreated || (loggedIn && isEmpty(customerAccountUUID)))) {
            if (this.state.showSoleTrader && !this.props.businessContactSameAsPersonalContact) {
                verifyBusinessAddress({ addressLine1: businessAddress }, async ({ hasError, data = {} }) => {
                    if (!hasError && data.addressId) {
                        payload.businessAddress = data.addressId;
                        await validateCustomerDetails(payload);
                    }
                });
            } else await validateCustomerDetails(payload);
        }
        // }
    }

    isPersonalEmailVerified = () => {
        const {
            personalEmailVerificationMeta: { lastVerifiedEmail, isEmailVerified },
            emailAddress
        } = this.props;
        if (isEmailVerified) addDataLayerEventInfo('impression', 'addEmail', 'newEmail', 'Contact Email');
        return lastVerifiedEmail === emailAddress;
    };

    validateBusinessRegistrationForm = () => {
        const {
            actions: { setBRInvalidMessageBox },
            businessReigstrationMeta = {}
        } = this.props;
        if (!businessReigstrationMeta.isABNOrACNValid) setBRInvalidMessageBox(true);
        return { valid: businessReigstrationMeta.isABNOrACNValid };
    };

    validateBusinessContactsForm = async () => {
        const {
            actions: { validateBusinessContactForm }
        } = this.props;

        const isBusinessContactFormValid = await new Promise((res) => {
            validateBusinessContactForm(({ isValid }) => {
                res(isValid);
            });
        });
        return {
            valid: isBusinessContactFormValid
        };
    };

    /**
     * Renders form errors
     *
     * @returns
     * @memberof ContactDetails
     */
    renderErrors() {
        const {
            verifyAddressId,
            address: { isVerificationRequired },
            errors
        } = this.props;
        const { addressField: { error: { generic: genericError = {}, validatecustomer = {} } = {} } = {}, createCartError = {} } = this.props.locale;
        const showAddressVerificationError = verifyAddressId === 'addressNotFound' && isVerificationRequired;
        let errorText = showAddressVerificationError ? genericError.addressNotFound : '';

        if (errors.hasError) {
            switch (errors.errorCode) {
                case SERVER_ERROR:
                    errorText = validatecustomer.serverError;
                    break;
                case BAD_REQUEST:
                    errorText = validatecustomer.serverError;
                    break;
                case SHOPPING_CART_CREATION_ERROR:
                    errorText = createCartError;
                    break;
                default:
                    break;
            }
        }

        return <Error errorText={errorText} />;
    }
}

/**
 * Valdiation of current form state
 *
 * @param {any} values - Form values
 * @param {any} props - error messages
 */
const validate = (values, props) => {
    document.getElementById('address-error-description') ? document.getElementById('address-error-description').style.display = 'block' : null;
    // TODO fix error messages to read from locale
    // const { emailIDElement, confirmEmailIDElement } = this.props.locale;
    const {
        loading: { isModuleLoaded }
    } = props;

    const errors = {};
    // if (!isModuleLoaded) return false;
    if (isEmpty(values.address)) {
        errors.address = 'Required field. Please search and select an address.';
    }
    const { isEsimActivation = false } = getUiState() || {};
    if (isEmpty(values.emailAddress)) {
        errors.emailAddress = 'Required field. Please enter your email address and verify access to it.';
    } else if (validateEmail(values.emailAddress, isEsimActivation)) {
        errors.emailAddress = 'Invalid email domain. Please enter a valid email address.';
    }
    if (isEmpty(values.address)) {
        errors.address = 'Required field. Please search and select an address.';
    }
    if (isEmpty(values.BusinessName)) {
        errors.BusinessName = 'Required field. Please enter your business name.';
    } else if (!validateBizName(values.BusinessName)) {
        errors.BusinessName = 'Invalid input. Please use alphanumeric characters only.';
    }
    if (isEmpty(values.ABN)) {
        errors.ABN = 'Required field. Please enter an ABN.';
    } else if (!validateAbnNumber(values.ABN)) {
        errors.ABN = 'Invalid entry. Please enter a valid ABN.';
    }
    return errors;
};

const fieldList = ['emailAddress', 'address', 'BusinessName'];

const YourContactDetailsRedux = reduxForm({
    form: 'yourContactDetailsForm',
    destroyOnUnmount: false,
    validate,
    fields: fieldList,
    onSubmitFail: submitFailure(fieldList),
    enableReinitialize: true
})(YourContactDetails);

const ConnectedStateAndForm = connect((state) => {
    // trigger form validation only when module is loaded
    const {
        address: { addressData }
    } = state.app.appData;
    const addressValue = addressData ? { label: addressData.address, value: addressData.addressId } : {};

    const identityDetailsForm = getFormValues('identityDetailsForm');
    const contactDetailsForm = getFormValues('yourContactDetailsForm');
    const formData = { ...identityDetailsForm, ...contactDetailsForm };
    // Get value of verify address, to copy in address field when selected
    const verifyAddressId = formValueSelector('yourContactDetailsForm')(state, 'verifyAddress');
    const verifyingBusinessAddress = state.yourContactDetails.addressVerificationMeta.verifying;

    // this is a redux form option to initiate validation once the app module is loaded
    const { loading: { isModuleLoaded } = {} } = state.yourContactDetails || {};
    return {
        shouldValidate: () => isModuleLoaded, verifyAddressId, addressValue, formData,
        verifyingBusinessAddress,
        formData,
        businessContactSameAsPersonalContact: formValueSelector('businessRegistrationForm')(state, 'businessContactSameAsPersonalContact')
    };
})(YourContactDetailsRedux);

export default ConnectedStateAndForm;
