/* eslint-disable max-len */
const ID_PAGE_CONSTANTS = {
    pageBlockImage: {
        desktop: {
            generic: {
                desc: ' ',
                image: '/img/telstra/SPPA/06-generic-image.jpg'
            },
            drivingLicense: {
                desc: 'Example of australian driving license',
                qldImage: {
                    imageSrc: '/img/telstra/SPPA/id-licence-qld.jpg',
                    altText: 'A Queensland driver licence. Licence number is highlighted in the top right.',
                    cardNoAltText:
                        'For licences issued before 2019, the licence number is highlighted on the front of the card in the top right, above the driver’s photo. Card number is highlighted on the back of the card on the bottom. For licences issued after 2019, the licence number is highlighted on the front of the card in the top right, above the driver’s photo. Card number is highlighted on the front and back of the card on the bottom.'
                },
                nswImage: {
                    imageSrc: '/img/telstra/SPPA/id-licence-nsw.jpg',
                    altText:
                        'A New South Wales driver licence. Given name and family name are highlighted in the top left, licence number is highlighted in the bottom left and date of birth is highlighted at the bottom in the middle.',
                    cardNoAltText: 'A New South Wales driver licence. Licence number is highlighted on the bottom left. Card number is highlighted on the top right, above the driver’s photo.'
                },
                ntImage: {
                    imageSrc: '/img/telstra/SPPA/id-licence-nsw.jpg',
                    altText: 'A Norther Territory Australian driver’s licence. Licence number is highlighted in the top right.',
                    cardNoAltText:
                        'A Northern Territory driver licence. Licence number is highlighted on the front of the card, below ‘Family Name’. Card number is highlighted on the back of the card on the bottom.'
                },
                saImage: {
                    imageSrc: '/img/telstra/SPPA/id-licence-nsw.jpg',
                    altText: 'A South Australian driver licence. Licence number is highlighted in the top left.',
                    cardNoAltText:
                        'A South Australia driver licence. Licence number is highlighted on the front of the card in the top left. Card number is highlighted on the back of the card on the top right.'
                },
                actImage: {
                    imageSrc: '/img/telstra/SPPA/id-licence-nsw.jpg',
                    altText: 'An Australian Capital Territory (ACT) driver licence. Surname and first name highlighted in the top left, date of birth and licence number highlighted in the middle left.',
                    cardNoAltText: 'An Australian Capital Territory (ACT) driver licence. Licence number is highlighted on the bottom left. Card number is highlighted on the right, next to the driver’s photo.'
                },
                waImage: {
                    imageSrc: '/img/telstra/SPPA/id-licence-nsw.jpg',
                    altText: 'A Western Australian driver’s licence. Licence number is highlighted in the top right.',
                    cardNoAltText:
                        'A Western Australia driver licence. Licence number is highlighted on the front of the card in the top right. Card number is highlighted on the back of the card on the bottom right.'
                },
                tasImage: {
                    imageSrc: '/img/telstra/SPPA/id-licence-nsw.jpg',
                    altText: 'A Tasmanian driver licence. Licence number is highlighted in the top left.',
                    cardNoAltText: 'A Tasmanian driver licence. Licence number is highlighted on the front of the card in the top left. Card number is highlighted on the back of the card on the top right.'
                },
                vicImage: {
                    imageSrc: '/img/telstra/SPPA/id-licence-nsw.jpg',
                    altText: 'A Victorian driver licence. The licence number is highlighted in the top right.',
                    cardNoAltText:
                        'A Victorian driver licence. For older licences, the licence number is highlighted on the front of the card on the top right, above the driver’s photo. The card number is highlighted on the back of the card in the middle on the right. For newer licences, the licence number is highlighted on the front of the card on the top right, above the driver’s photo. The card number is highlighted on the back of the card on the top right.'
                }
            },
            australianPassport: {
                altText:
                    'An Australian passport. The last name and given names are highlighted in the middle. The Dates of issue and expire are highlighted in the bottom in the middle. The passport number is highlighted in the top right. ',
                image: '/img/telstra/SPPA/id-passport.jpg'
            },
            medicareCard: {
                altText:
                    'A Medicare card. The Medicare number is highlighted in the middle towards the top. The name of each person is highlighted on the card with the individual reference number, e.g. "1" or "2" before the name. The valid to date is located in the bottom right.',
                image: '/img/telstra/SPPA/05-medicare.jpg'
            },
            australianVisa: {
                altText: 'An International passport. International passport number is highlighted in the top right.',
                image: '/img/telstra/SPPA/id-international.jpg'
            },
            immiCard: {
                altTextEvImmiCard: 'An Evidence of Immigration Status card that shows your details. The ImmiCard number is highlighted at the bottom of the card between the photo and card expiry.',
                altTextAusImmiCard: 'An Australian Migration Status card that shows your details. The ImmiCard number is highlighted at the bottom of the card between the photo and card expiry.'
            }
        },
        mobile: {
            generic: {
                desc: 'Pick one of four ID types..',
                image: '/assets/img/telstra/SPPA/06-generic-image.jpg'
            },
            drivingLicense: {
                desc: 'Example of australian driving license',
                qldImage: {
                    imageSrc: '/assets/img/telstra/SPPA/id-licence-qld.jpg',
                    altText: 'A Queensland driver licence. Licence number is highlighted in the top right.',
                    cardNoAltText:
                        'For licences issued before 2019, the licence number is highlighted on the front of the card in the top right, above the driver’s photo. Card number is highlighted on the back of the card on the bottom. For licences issued after 2019, the licence number is highlighted on the front of the card in the top right, above the driver’s photo. Card number is highlighted on the front and back of the card on the bottom.'
                },
                nswImage: {
                    imageSrc: '/assets/img/telstra/SPPA/id-licence-nsw.jpg',
                    altText:
                        'A New South Wales driver licence. Given name and family name are highlighted in the top left, licence number is highlighted in the bottom left and date of birth is highlighted at the bottom in the middle.',
                    cardNoAltText: 'A New South Wales driver licence. Licence number is highlighted on the bottom left. Card number is highlighted on the top right, above the driver’s photo.'
                },
                ntImage: {
                    imageSrc: '/assets/img/telstra/SPPA/id-licence-nt.jpg',
                    altText: 'A Norther Territory Australian driver’s licence. Licence number is highlighted in the top right.',
                    cardNoAltText:
                        'A Northern Territory driver licence. Licence number is highlighted on the front of the card, below ‘Family Name’. Card number is highlighted on the back of the card on the bottom.'
                },
                saImage: {
                    imageSrc: '/assets/img/telstra/SPPA/id-licence-sa.jpg',
                    altText: 'A South Australian driver licence. Licence number is highlighted in the top left.',
                    cardNoAltText:
                        'A South Australia driver licence. Licence number is highlighted on the front of the card in the top left. Card number is highlighted on the back of the card on the top right.'
                },
                actImage: {
                    imageSrc: '/assets/img/telstra/SPPA/id-licence-act.jpg',
                    altText: 'An Australian Capital Territory (ACT) driver licence. Surname and first name highlighted in the top left, date of birth and licence number highlighted in the middle left.',
                    cardNoAltText: 'An Australian Capital Territory (ACT) driver licence. Licence number is highlighted on the bottom left. Card number is highlighted on the right, next to the driver’s photo.'
                },
                waImage: {
                    imageSrc: '/assets/img/telstra/SPPA/id-licence-wa.jpg',
                    altText: 'A Western Australian driver’s licence. Licence number is highlighted in the top right.',
                    cardNoAltText:
                        'A Western Australia driver licence. Licence number is highlighted on the front of the card in the top right. Card number is highlighted on the back of the card on the bottom right.'
                },
                tasImage: {
                    imageSrc: '/assets/img/telstra/SPPA/id-licence-tas.jpg',
                    altText: 'A Tasmanian driver licence. Licence number is highlighted in the top left.',
                    cardNoAltText: 'A Tasmanian driver licence. Licence number is highlighted on the front of the card in the top left. Card number is highlighted on the back of the card on the top right.'
                },
                vicImage: {
                    imageSrc: '/assets/img/telstra/SPPA/vic-licence.jpg',
                    altText: 'A Victorian driver licence. The licence number is highlighted in the top right.',
                    cardNoAltText:
                        'A Victorian driver licence. For older licences, the licence number is highlighted on the front of the card on the top right, above the driver’s photo. The card number is highlighted on the back of the card in the middle on the right. For newer licences, the licence number is highlighted on the front of the card on the top right, above the driver’s photo. The card number is highlighted on the back of the card on the top right.'
                }
            },
            australianPassport: {
                desc: '',
                altText:
                    'An Australian passport. The last name and given names are highlighted in the middle. The Dates of issue and expire are highlighted in the bottom in the middle. The passport number is highlighted in the top right. ',
                image: '/assets/img/telstra/SPPA/id-international.jpg'
            },
            medicareCard: {
                desc: ' ',
                altText:
                    'A Medicare card. The Medicare number is highlighted in the middle towards the top. The name of each person is highlighted on the card with the individual reference number, e.g. "1" or "2" before the name. The valid to date is located in the bottom right.',
                image: '/assets/img/telstra/SPPA/05-medicare.jpg'
            },
            australianVisa: {
                desc: '',
                altText: 'An International passport. International passport number is highlighted in the top right.',
                image: '/assets/img/telstra/SPPA/id-international.jpg'
            },
            immiCard: {
                desc: '',
                altTextEvImmiCard: 'An Evidence of Immigration Status card that shows your details. The ImmiCard number is highlighted at the bottom of the card between the photo and card expiry.',
                altTextAusImmiCard: 'An Australian Migration Status card that shows your details. The ImmiCard number is highlighted at the bottom of the card between the photo and card expiry.'
            }
        }
    },
    pageTitle: 'Identity verification',
    subTitle: {
        personal: 'We need to confirm your identity so that we comply with government regulations for Pre-Paid.',
        business:
            'Before we activate your service, we need to confirm your identity so that we comply with government regulations for pre-paid services. Enter the details exactly as they appear on your chosen form of ID: '
    },
    idTypeElement: {
        label: 'ID type',
        defaultOptionLabel: 'Please select',
        options: [
            {
                label: "Australian driver licence or learner's permit number",
                value: 'AUS_DRIVERS_LICENSE',
                disabled: false
            },
            {
                label: 'Medicare card',
                value: 'MEDICARE_CARD',
                disabled: false
            },
            {
                label: 'Australian passport',
                value: 'AUS_PASSPORT',
                disabled: false
            },
            {
                label: 'International passport',
                value: 'AUS_VISA',
                disabled: false
            },
            {
                label: 'ImmiCard',
                value: 'IMMI_CARD',
                disabled: false
            }
        ],
        error: 'Required field. Please select an ID type.',
        exampleLabel: 'View example'
    },
    idDetails: {
        drivingLicense: {
            desc: ' ',
            enabled: 'true',
            dlStateElement: {
                label: 'State of issue',
                defaultOptionLabel: 'Please select',
                options: [
                    { value: 'NSW', label: 'NSW' },
                    { value: 'QLD', label: 'QLD' },
                    { value: 'NT', label: 'NT' },
                    { value: 'SA', label: 'SA' },
                    { value: 'ACT', label: 'ACT' },
                    { value: 'WA', label: 'WA' },
                    { value: 'TAS', label: 'TAS' },
                    { value: 'VIC', label: 'VIC' }
                ],
                error: {
                    empty: 'Required field. Please select a state of issue.'
                }
            },
            dlNumberElement: {
                label: 'Licence number',
                placeholder: '',
                error: {
                    empty: 'Required field. Please enter your licence number.',
                    invalid: 'Invalid input. Please enter a valid licence number.'
                }
            },
            dlCardNumberElement: {
                label: 'Card number',
                placeholder: '',
                error: {
                    empty: 'Required field. Please enter your card number.',
                    invalid: 'Invalid input. Please enter a valid card number.'
                }
            },
            dlFirstNameElement: {
                label: 'Given name/s',
                autocomplete: 'given-name',
                placeholder: '',
                error: {
                    empty: 'Required field. Please enter your given name.',
                    invalid: 'Invalid input. Please use alphabetic characters only.',
                    invalid_Duplicate: 'Invalid input. Please enter your name only, avoiding titles, acronyms and post-noms.'
                }
            },
            dlMiddleNameElement: {
                label: 'Middle name/s',
                placeholder: '',
                autocomplete: 'additional-name',
                noMiddleNameLabel: 'I don’t have a middle name on my ID',
                error: {
                    empty: 'Required field. Please enter your middle name.',
                    invalid: 'Invalid input. Please use alphabetic characters only.',
                    invalid_Duplicate: 'Invalid input. Please enter your name only, avoiding titles, acronyms and post-noms.'
                }
            },
            dlLastNameElement: {
                label: 'Family name',
                autocomplete: 'family-name',
                placeholder: '',
                error: {
                    empty: 'Required field. Please enter your family name.',
                    invalid: 'Invalid input. Please use alphabetic characters only.',
                    invalid_Duplicate: 'Invalid input. Please enter your name only, avoiding titles, acronyms and post-noms.'
                }
            },
            dobElement: {
                label: 'Date of birth',
                autocomplete: 'bday',
                error: {
                    empty: 'Required field. Please use format DD/MM/YYYY.',
                    invalid: 'Invalid input. Please use format DD/MM/YYYY.',
                    dobMinError: 'Invalid input. The SIM owner must be at least 10 years old.',
                    dobMaxError: 'Invalid input. Please enter a valid date of birth.'
                }
            },
            dlAcceptTermsElement: {
                label: 'I agree to have my ID checked with the issuer of that ID.',
                acceptTermsNote: 'Telstra won’t store your information, except your name and date of birth, if required.',
                error: {
                    empty: 'Required field. Please tick the box to continue.'
                }
            },
            salutation: {
                label: 'Title',
                default: 'Please select',
                autocomplete: 'honorific-prefix',
                error: 'Required field. Please select a title.',
                options: [
                    { value: 'mr', label: 'Mr' },
                    { value: 'mrs', label: 'Mrs' },
                    { value: 'miss', label: 'Miss' },
                    { value: 'ms', label: 'Ms' }
                ]
            }
        },
        australianPassport: {
            desc: '',
            enabled: 'true',
            appNumberElement: {
                label: 'Passport number',
                placeholder: '',
                error: {
                    empty: 'Required field. Please enter your passport number.',
                    invalid: 'Invalid input. Please enter a valid passport number.'
                }
            },
            appFirstNameElement: {
                label: 'Given name',
                placeholder: 'Enter your first name as per passport',
                error: {
                    empty: 'Required field. Please enter your given name.',
                    invalid: 'Invalid input. Please use alphabetic characters only.'
                }
            },
            appMiddleNameElement: {
                label: 'Middle Name',
                placeholder: 'Enter your middle name as per passport',
                noMiddleNameLabel: 'I don’t have a middle name',
                error: {
                    empty: 'Middle name cannot be blank. Please enter your middle name if you have one.',
                    invalid: 'Invalid input. Please use alphabetic characters only.'
                }
            },
            appLastNameElement: {
                label: 'Last name',
                placeholder: 'Enter your last name as per passport',
                error: {
                    empty: 'Last name cannot be blank. Please enter your last name',
                    invalid: 'Invalid input. Please use alphabetic characters only.'
                }
            },
            appdobElement: {
                label: 'Date of birth',
                error: {
                    invalid: 'Please enter a valid date of birth.',
                    dobMinError: 'The minimum age is 10 years. Please enter a valid date of birth.'
                }
            },
            appGenderElement: {
                label: 'Your gender',
                options: [
                    { value: 'default', label: 'Select gender' },
                    { value: 'M', label: 'M' },
                    { value: 'F', label: 'F' }
                ],
                error: {
                    empty: 'Please select your gender'
                }
            },
            appAcceptTermsElement: {
                label: 'I agree to have my ID checked with the issuer.',
                acceptTermsNote:
                    "Telstra will store your information in order to set up and manage your account. Your information will be treated in accordance with our <a target='_blank' href='https://www.telstra.com.au/privacy/privacy-statement'> Privacy Statement </a>.",
                error: {
                    empty: 'Required field. Please tick the box to continue.'
                }
            },
            appTitle: {
                label: 'Title',
                default: 'Please select',
                error: 'Required field. Please select a title.',
                options: [
                    { value: 'mr', label: 'Mr' },
                    { value: 'mrs', label: 'Mrs' },
                    { value: 'miss', label: 'Miss' },
                    { value: 'ms', label: 'Ms' }
                ]
            }
        },
        medicareCard: {
            desc: ' ',
            enabled: 'true',
            mcCardNumberElement: {
                label: 'Medicare card number',
                placeholder: 'Enter Medicare number',
                error: {
                    empty: 'Required field. Please enter your Medicare card number.',
                    invalid: 'Invalid field. Please enter a valid Medicare card number.'
                }
            },
            mccardColorElement: {
                label: 'Card colour',
                options: [
                    { value: '', label: 'Select card colour' },
                    { value: 'G', label: 'Green' },
                    { value: 'B', label: 'Blue' },
                    { value: 'Y', label: 'Yellow' }
                ],
                error: {
                    empty: 'Required field. Please select an option.'
                }
            },
            mcCardExpiryElement: {
                label: 'Expiry date',
                error: {
                    invalid: 'Invalid input. Please use format DD/MM/YYYY.',
                    invalidForGreenCard: 'Invalid input. Please use format MM/YYYY.',
                    pastExpiry: 'Invalid input. Please enter a valid expiry date (can’t be a past date).'
                }
            },
            mcRefNumberElement: {
                label: 'Individual reference number',
                options: [
                    { value: 'default', label: 'Select' },
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                    { value: '5', label: '5' },
                    { value: '6', label: '6' },
                    { value: '7', label: '7' },
                    { value: '8', label: '8' },
                    { value: '9', label: '9' }
                ],
                error: {
                    empty: 'Required field. Please select an option.'
                }
            },
            mcFirstNameElement: {
                label: 'First name',
                placeholder: 'Enter your first name as per ID',
                error: {
                    empty: 'First name cannot be blank. Please enter your first name.',
                    invalid: "The first name entered is invalid. The characters entered must be a to z 0 to 9 hyphen fullstop or apostrophe (') only."
                }
            },
            mcMiddleNameElement: {
                label: 'Middle name',
                placeholder: 'Enter your middle name as per ID',
                noMiddleNameLabel: 'I don’t have a middle name',
                error: {
                    empty: 'Middle name cannot be blank. Please enter your middle name if you have one.',
                    invalid: "The middle name entered is invalid. The characters entered must be a to z 0 to 9 hyphen fullstop or apostrophe (') only."
                }
            },
            mcLastNameElement: {
                label: 'Last name',
                placeholder: 'Enter your middle name as per ID',
                error: {
                    empty: 'Last name cannot be blank. Please enter your last name.',
                    invalid: "The last name entered is invalid. The characters entered must be a to z 0 to 9 hyphen fullstop or apostrophe (') only."
                }
            },
            mcDobElement: {
                label: 'Date of birth',
                error: {
                    invalid: 'Please enter a valid date of birth.',
                    dobMinError: 'The minimum age is 10 years. Please enter a valid date of birth.'
                }
            },
            mcCardAcceptTermsElement: {
                label: 'I agree to have my ID checked with the issuer.',
                acceptTermsNote:
                    "Telstra will store your information in order to set up and manage your account. Your information will be treated in accordance with our  <a target='_blank' href='https://www.telstra.com.au/privacy/privacy-statement'> Privacy Statement </a>.",
                error: {
                    empty: 'Please agree to have your ID checked by ticking the box'
                }
            },
            mcTitle: {
                label: 'Title',
                default: 'Please select',
                error: 'Required field. Please select a title.',
                options: [
                    { value: 'mr', label: 'Mr' },
                    { value: 'mrs', label: 'Mrs' },
                    { value: 'miss', label: 'Miss' },
                    { value: 'ms', label: 'Ms' }
                ]
            }
        },
        australianVisa: {
            desc: '',
            enabled: 'true',
            intlppNumberElement: {
                label: 'Passport number',
                placeholder: 'Enter passport number',
                error: {
                    empty: 'Required field. Please enter your passport number.',
                    invalid: 'Invalid input. Please enter a valid passport number.'
                }
            },
            intlppCountryElement: {
                label: 'Country of issue',
                options: [{ value: '', label: 'Select country of issue' }],
                error: {
                    empty: 'Required field. Please select a country.'
                }
            },
            intlppFirstNameElement: {
                label: 'Given name',
                placeholder: 'Enter your first name as per passport',
                error: {
                    empty: 'First name cannot be blank. Please enter your first name.',
                    invalid: "The first name entered is invalid. The characters entered must be a-z, A-Z, hyphen (-) or apostrophe (') only."
                }
            },
            intlppMiddleNameElement: {
                label: 'Middle name',
                placeholder: 'Enter your middle name as per passport',
                noMiddleNameLabel: 'I don’t have a middle name',
                error: {
                    empty: 'Middle name cannot be blank. Please enter middle name if you have one.',
                    invalid: "The middle name entered is invalid. The characters entered must be a-z, A-Z, hyphen (-) or apostrophe (') only."
                }
            },
            intlppLastNameElement: {
                label: 'Last name',
                placeholder: 'Enter your last name as per passport',
                error: {
                    empty: 'Last name cannot be blank. Please enter your last name.',
                    invalid: "The last name entered is invalid. The characters entered must be a-z, A-Z, hyphen (-) or apostrophe (') only."
                }
            },
            intlppdobElement: {
                label: 'Date of birth',
                error: {
                    invalid: 'Please enter a valid date of birth.',
                    dobMinError: 'The minimum age is 10 years. Please enter a valid date of birth.'
                }
            },
            intlppAcceptTermsElement: {
                label: 'I agree to have my ID checked with the issuer.',
                acceptTermsNote:
                    "Telstra will store your information in order to set up and manage your account. Your information will be treated in accordance with our  <a target='_blank' href='https://www.telstra.com.au/privacy/privacy-statement'> Privacy Statement </a>.",
                error: {
                    empty: 'Please agree to have your ID checked by ticking the box'
                }
            },
            ntlppTitle: {
                label: 'Title',
                default: 'Please select',
                error: 'Required field. Please select a title.',
                options: [
                    { value: 'mr', label: 'Mr' },
                    { value: 'mrs', label: 'Mrs' },
                    { value: 'miss', label: 'Miss' },
                    { value: 'ms', label: 'Ms' }
                ]
            }
        },
        immiCard: {
            desc: '',
            enabled: 'true',
            imCardNumberElement: {
                label: 'ImmiCard number',
                placeholder: '',
                error: {
                    empty: 'Required field. Please enter your immicard number.',
                    invalid: 'Invalid input. Please enter a valid immicard number.'
                }
            }
        }
    },
    pageError: {
        intermittentFailure: {
            drivingLicense: {
                preCountText: 'Please check that the details below match your ID exactly. You have ',
                postCountText: ' attempts left.'
            },
            australianPassport: {
                preCountText: 'Please check that the details below match your ID exactly. You have ',
                postCountText: ' attempts left'
            },
            medicareCard: {
                preCountText: 'Please check that the details below match your ID exactly. You have ',
                postCountText: ' attempts left.'
            },
            australianVisa: {
                preCountText: 'Please check that the details below match your ID exactly. You have ',
                postCountText: ' attempts left.'
            },
            immiCard: {
                preCountText: 'Please check that the details below match your ID exactly. You have ',
                postCountText: ' attempts left'
            }
        },
        backedIDServiceFailure: 'Few of our ID Backend services are currently undergoing downtime and hence have been disbaled',
        terminalFailure: 'The government database that we use to verify your ID is experiencing technical difficulties. Try using another form of ID or try again later. ',
        maxAttemptFailure: "The ID information you've provided couldn't be matched against the government database. Please restart your activation or contact 24x7 Chat"
    },
    verifyButtonElement: {
        label: 'Continue'
    },
    nextButtonElement: {
        label: 'Next'
    },
    liveChat: {
        label: 'Live Chat',
        section: ['telstra', 'dtq', 'prepaid']
    },
    validationError: 'There is an error in one or more of the fields below. Please check and try again.',
    validationSuccessMsg: 'Your ID check was successful.',
    customerDetails: {
        introRadioGroup: {
            paragraph: 'What will you be using this service for?',
            option1: 'Personal ',
            option2: 'Sole Trader',
            option3: 'Business'
        },
        personalForm: {
            paragraph: ''
        },
        businessForm: {
            paragraph: 'Business customers will need an ABN or ACN, plus the name of an authorised representative.',
            abn: {
                label: 'ABN',
                placeholder: 'Enter your ABN',
                error: 'Please enter your business ABN',
                invalid: 'Please enter a valid ABN'
            },
            companyName: {
                label: 'Business name',
                placeholder: 'Enter your business name',
                error: 'Business Name cannot be empty',
                invalid: 'Business Name is invalid'
            }
        },
        soleTraderForm: {
            paragraph: '',
            abn: {
                label: 'ABN',
                placeholder: 'Enter your ABN',
                error: 'Please enter your business ABN',
                invalid: 'Please enter a valid ABN'
            }
        }
    }
};

export default ID_PAGE_CONSTANTS;
